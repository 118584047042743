@import vars

.button-area
  display: flex
  align-items: center
  width: 100%
  &._between
    justify-content: space-between
  &._center
    justify-content: center
  &._left
    justify-content: flex-end
  &._right
    justify-content: flex-start
  &._wrap
    flex-wrap: wrap

/* Buttons styles start */
button
  position: relative
  display: inline-block
  box-sizing: border-box
  border: none
  margin: 0
  text-decoration: none
  background: #0069ed
  color: #ffffff
  font-size: 1rem
  line-height: 1
  cursor: pointer
  text-align: center
  -webkit-appearance: none
  -moz-appearance: none
  min-height: 33px
  transition: $transition

button::-moz-focus-inner
  border: 0

/* Button styles end */
.btn
  padding: 0.5rem 1.6rem
  display: flex
  align-items: center
  justify-content: center
  text-transform: uppercase
  &:hover
    cursor: pointer

.btn-yellow-gradient
  background: linear-gradient(180deg, #FFCC00 0%, #FFB931 100%)
  border-radius: 16px
  @include modText(17px, 18px,  #303030, 600, null)
  &:hover, &:active
    background: #EBBC00

.btn-yellow-border
  @include modText(17px, 18px,  #303030, 600, null)
  background: #FFFFFF
  border: 1px solid #FFCC00
  border-radius: 16px
  &:hover, &:active
    background: #EBBC00

.btn-yellow
  @include modText(1.1rem, 18px,  #303030, 600, null)
  background: #FFCC00
  border-radius: 16px
  &:hover, &:active
    background: #EDBE00

.btn-border
  @include modText(10px, 14px,  #303030, 600, null)
  background: #fff
  border:  1px solid #C5CBD9
  border-radius: 16px
  text-transform: uppercase
  &:hover, &:active
    background: #EDBE00

.btn-gray
  @include modText(10px, 14px,  #303030, 600, null)
  background: #EAE9E9
  border-radius: 16px
  text-transform: uppercase
  &:hover, &:active
    background: #DFDADA

.btn-red
  @include modText(10px, 14px,  #fff, 600, null)
  background: #f00
  border-radius: 16px
  text-transform: uppercase
  &:hover, &:active
    background: #c90202

.btn-blue
  @include modText(16px, 22px,  #0E63DC, 600, null)
  background: #fff
  border-radius: 16px
  border: 1px solid #0E63DC
  width: 220px
  height: 62px
  text-transform: inherit
  &:hover, &:active
    color: #0A55C1
    border-color: #0A55C1

.btn--header
  font-size: 1rem
  padding: 0.5rem 2rem