@import "../../assets/styles/vars"

.header
  // background: linear-gradient(276.9deg, #FFCC00 -8.35%, #FFD29C 53.25%, #CDDCFE 94.55%)
  background-color: #FFCC00
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2)
  min-height: 54px
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 13px 0 15px
  margin: 0 auto
  max-width: 640px
  margin-bottom: 2rem
  border-radius: 8px
  z-index: 2
  border-top-left-radius: 0
  border-top-right-radius: 0

.header-content
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  max-width: 640px
  margin: 0 auto

.header-title
  text-transform: uppercase
  @include modText(16px, 20px,  #2A2B31, 700, 700)
  width: 40%
  text-align: center

.header-nav
  width: 30%
  display: flex
  align-items: center

.header-icon
  width: 30%
  height: 20px
  display: inline-flex
  align-items: center
  justify-content: flex-end
  

.header-icon__button
  background-color: transparent
  padding: 0
  display: inline-flex
  align-items: center

.back--nav
  display: inline-flex
  align-items: center
  &__img
    height: 30px
    width: 24px
    fill: #454548
    stroke: #454548
    stroke-width: 10px






