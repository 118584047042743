@import "vars"

.banner-img--mobile
  display: none

@media (max-width: 1200px)
  .publication-list
    flex-wrap: wrap
  .publication-item-area
    flex: 1 1 50%
    margin: 0 0 20px 0
  .order-system-btn
    margin-top: 7rem
@media (max-width: 1100px)
  .call-area
    right: 100px
@media (max-width: 992px)
  .container-main-area
    padding: 0 1rem
  .home-header
    display: none
  .home-header-mobile
    position: relative
    padding: 16px 13px
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 -13px
  .header-logo-area_m
    width: 190px
    height: 29px
    margin: 0 41px 0 23px
    display: none
    img
      max-width: 100%
  .header-drop-menu-area_m
    display: flex
  .banner, .banner-slider .slick-list
    height: 300px
  .banner-logo
    margin: 0
    text-align: center
  //   display: none
  .banner-info
    padding-top: 5rem
  .banner-slider .slider-arrow
    display: none
  .banner-price
    width: 60%
    max-width: 420px
    min-width: 250px
    padding-left: 50px
    &__total
      font: 400 25px/28px

  .banner-text
    margin: 37px 0 32px 0
    @include modText(14px, 18px, #303030, 400, null)
  .banner-logo__container
    text-align: center
  .order-system-btn
    width: 320px
    padding: 1rem
    height: auto
    margin-top: 12rem
    @include modText(1.1rem, 20px, #303030, 600, null)
  .banner-logo
    font-size: 2.6rem
  .about-area
    padding: 20px 0 35px 0
  .title-home.title-block
    margin: 0 0 10px 0
    padding: 0
    .title
      @include modText(21px, 31px, #303030, 600, null)
      text-align: center
  .title-sub
    @include modText(15px, 22px, #636363, 400, 600)
    margin: 0 0 17px 0
    letter-spacing: -0.02em
  .about-info p
    @include modText(13px, 20px, #000, 300, null)
  .advantages-area
    padding: 30px 0 0 0
    margin: 0
  .slider-advantages
    margin: 0 -14px
  .top-line .title:after
    width: 45px
    height: 3px
    top: -19px
    left: calc(50% - 22.5px)
  .advantages-fon
    background-image: none
  .card-title-block
    margin: 0 0 10px 0
    .card-title
      letter-spacing: -0.02em
      @include modText(17px, 25px, #2E2E2E, 600, null)
  .card-info
    letter-spacing: -0.02em
    @include modText(15px, 22px, #636363, 400, null)
  .slider + .button-area
    display: none
  .publication-area
    padding: 21px 0 10px 0
  .publication-item-area
    margin: 0 0 16px 0
    // flex: 1 1 auto
    flex: 1 1 100%
  .publication-item-img
    overflow: hidden
    border-radius: 20px
    margin: 0 0 13px

  .price-table
    margin: 1rem auto 1rem
    max-width: 340px

  .price-th
    padding: 0.3rem
    font-size: 0.8rem

  .price-td
    padding: 0.5rem 0.3rem
    font-size: 1rem
    
  .publication-item
    margin: 0 2px
    align-items: center
    width: 100%
  .publication-item-title
    min-height: auto
    margin: 0
    @include modText(15px, 20px, #2E2E2E, 400, null)
  .publication-item-text
    display: none
  .publication-item
    width: 135px
  .system-area
    padding: 0px 0 25px 0
  .system-img-area,.call-area,.order-system-area,
  .system-list
    display: none
  .order-system-area--mobile
    display: flex
    align-items: center
    margin-bottom: 1rem
  .order-system-area.order-system-area--mobile .btn
    margin: 0
  .system-info-area
    display: block
  .system-list-slider
    display: block
    .system-item
      padding: 0 14px
      width: 100%
      margin: 0
      .check-block
        position: relative
        width: 100%
        display: flex
        justify-content: center
        margin: 0 0 25px 0
      .system-title
        margin: 0 0 9px 0
        @include modText(17px, 23px, #2E2E2E, 600, null)
      .system-info
        letter-spacing: -0.02em
        @include modText(15px, 22px, #636363, 400, null)
  .system-img-mini-area
    display: flex
  .system-sound-area
    align-items: center
  .system-sound-title
    padding: 0
    margin: 0 0 15px 0
  .system-player
    margin: 0 15px 20px 15px
  .contact-area
    flex-wrap: wrap
    flex-direction: column
    justify-content: center
    padding: 45px 0 48px 0
  .contact-info
    text-align: center
    .title-home.title-block
      justify-content: center
    .top-line .title:after
      left: calc(50% - 22.5px)
  .contact-info-text
    @include modText(15px, 22px, #636363, 400, null)
    margin: 0 0 20px 0
  .contact-way
    margin: 0 0 10px 0
  .contact-way + .contact-way
    margin: 0 0 10px
  .contact-ways
    flex-direction: column
    flex-wrap: wrap
    justify-content: center
    margin: 0 0 20px 0
  .contact-form-area
    margin: 0
    max-width: 100%
    display: flex
    justify-content: center
  .footer-nav-area
    flex-wrap: wrap
  .footer-soc
    p
      display: none
    .soc-area
      margin: 0
  .modal.modal-agreement
    padding: 20px 15px
    height: 100% !important
  .card-info-block
    min-height: 300px

  header.container-area-field
    position: absolute
  .header-color
    background-color: transparent

  .banner__back
    margin: 0
  .banner-text
    margin: 0
    color: #fff
    text-shadow: 1px 0 10px #000
    font-weight: 500
    font-size: 1.3rem
    margin-bottom: 1rem
    margin-top: 0.5rem
    letter-spacing: -0.1px
    text-align: center
  .banner, .banner-slider .slick-list, .banner .container-main-area
    max-height: 661px
    height: 100vh
    height: 100svh
    position: relative
  .banner .container-main-area
    padding-top: 5.4rem

  .banner-img
    display: none

  .banner-img--mobile
    display: block

  .order-system-btn
    position: absolute
    width: auto
    bottom: 2rem
    left: 1rem
    right: 1rem
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px

@media (max-width: 576px)
  .system-player
    padding: 10px 10px
  .player-next
    margin: 0 0 0 10px
  .rhap_progress-container
    margin: 0 5px