.rdt input[type="text"]
    font-size: 0.9rem
    line-height: 18px
    color: #636363
    font-weight: 600
    padding: 0.4rem 0.5rem
    background: #fff
    outline: 0
    border: 2px solid #979797
    border-radius: 14px
    width: 100%
    margin: 0

.time-only
    .rdtPicker
        width: 102px
        border-radius: 16px