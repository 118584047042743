@import "button"
@import "input"
@import "switch"

img
  max-width: 100%
.center-style
  display: flex
  justify-content: center
  align-items: center
  flex: 1
  width: 100%
.container-area-field
  width: 100%
  position: relative
  display: flex
  justify-content: center

.container-main-area
  position: relative
  max-width: 1230px
  padding: 0 2rem
  width: 100%

.big-container
  max-width: 1440px
  width: 100%

.home-header
  padding: 13px 0
  width: 100%

.header-container
  z-index: 30

.home-header-mobile
  display: none
  position: relative
  z-index: 20

.header-color
  background-color: #ECF3FC
  // background-color: #fff

.about-color
  background-color: #F5F5F5 !important

.home-header-info
  margin: 0 -5px
  display: flex
  align-items: center
  justify-content: space-between

.header-drop-menu-area_m
  transition: $transition
  display: none
  background-color: #FFFFFF
  position: absolute
  bottom: 1000%
  left: 0
  width: 100%
  padding: 1rem
  z-index: 10
  flex-direction: column
  align-items: center
  transform: translateY(100%)
  .drop-menu-link:last-child
    border-bottom: none
  &._open
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
    bottom: 72px
    padding-top: 4.4rem
    padding-bottom: 1rem

.drop-menu-link
  text-align: center
  width: 100%
  padding: 0.8rem 0.5rem
  text-transform: uppercase
  @include modText(1rem, 16px, #2A2B31, 400, null)
  &:active
    font-weight: bold

.header-info-area
  margin: 5px 5px
  display: flex
  align-items: center
  gap: 0.5rem

.header-icon
  width: 24px
  height: 18px
  display: flex
  align-items: center
  justify-content: center

.header-info
  white-space: nowrap
  display: flex
  align-items: center
  @include modText(15px, 15px, #2A2B31, 400, 600)
  .work-time
    font-size: 13px
    text-transform: uppercase
    margin: 0 0 0 8px
    padding: 0 0 0 7px
    border-left: 1px solid #2A2B31

.home-header-nav
  display: flex
  align-items: center
  .button-area
    width: auto
    margin: 0 0 0 auto
    .btn
      @include modText(1rem, 17px, #2A2B31, 600, null)
      padding: 0.5rem 3rem

.home-navigation
  flex: 1 1 auto
  // margin: 0 57px 0 0
  display: flex
  // justify-content: space-between

.home-navigation
  .navigation-link:last-child
    margin: 0

.navigation-link
  white-space: nowrap
  margin: 0 20px 0 0
  text-transform: uppercase
  @include modText(13px, 18px, #2A2B31, 600, null)
  position: relative
  &:before
    transition: $transition
    opacity: 0
    content: ''
    width: 34px
    height: 4px
    background: #FFCC00
    border-radius: 1.25px
    position: absolute
    bottom: -4px
    left: calc(50% - 17px)
  &:hover
    cursor: pointer
    &:before
      opacity: 1

.slider
  position: relative
  div
    outline: none
.banner-slider
  position: relative
  .container-main-area
    margin: 0 auto
  .slick-track
    display: flex
  .slick-list
    height: 470px
  .slider-arrow
    cursor: pointer
    position: absolute
    top: calc(50% - 30px)
    z-index: 10
    &._prew
      left: 60px
    &._next
      right: 60px

.banner
  width: 100%
  height: 470px
  position: relative

.banner-price
  position: absolute
  right: 0
  bottom: -4px
  z-index: 1
  background-image: url("../images/landing/banner-price.png")
  width: 563px
  height: 75px
  display: flex
  align-items: center
  justify-content: center
  display: none
  img
    width: 100%
  &__total
    font: 400 40px/55px
    color: #fff
    text-transform: uppercase
    span
      font-weight: 600

.banner-info
  width: 100%
  height: auto

.banner-logo
  color: #FFCC00
  font-size: 3rem
  margin-top: 3rem

.h1
  margin: 0
  margin-top: 2rem

.banner-text
  @include modText(21px, 28px, #292B39, 400, null)
  color: #fff
  text-shadow: 1px 0 10px #000
  font-weight: 500
  font-size: 1.3rem
  margin-top: 1rem

.order-system-btn
  width: 460px
  height: 60px
  @include modText(18px, 20px, #303030, 600, null)
  margin-top: 12rem

.banner-img
  width: 100%
  position: absolute
  left: 0
  top: 0
  height: 100%
  object-fit: cover
  object-position: 0 -127px

.about-area
  padding: 46px 0 86px 0

.title-home.title-block
  justify-content: center
  margin: 0 0 16px 0
  .title
    letter-spacing: -0.02em
    @include modText(40px, 60px, #303030, 600, null)

.title-sub
  margin: 0 0 32px 0
  @include modText(20px, 30px, #636363, 400, 600)

.about-info
  p
    @include modText(17px, 24px, #000, 300, null)

.advantages-fon
  background-image: url("../images/landing/fon.png")
  width: 100%
  //height: 100%
  background-repeat: no-repeat
  background-position: center
  background-size: cover

.top-line
  .title:after
    transition: $transition
    opacity: 1
    content: ''
    width: 90px
    height: 6px
    background: #FFCC00
    border-radius: 1.25px
    position: absolute
    top: -31px
    left: calc(50% - 45px)

.advantages-area
  padding: 69px 40px 30px 40px
  background-color: #fff
  margin: 0 0 30px 0

.slider
  display: flex
  & + .button-area
    margin: 40px 0 0 0

.slider-advantages
  padding: 0 0 10px 0
  display: block
  .slick-slider
    width: 100%
  button
    display: none!important
  .slick-track
    display: flex
    //padding: 0 50px 50px 50px
    justify-content: space-between
  .slick-slide
    display: flex
    flex-wrap: nowrap
    justify-content: center
  //margin: 0 -15px
  .slick-list
    //overflow: hidden
    //padding: 0  15px 10px 15px

.slider-card-area
  max-width: 282px
  position: relative
  display: block
  padding: 84px 0 0 0
  border-radius: 20px
  //margin: 0 12px 30px 12px
  margin: 0 12px 25px 12px

.card-image-block
  position: absolute
  width: 100%
  display: flex
  justify-content: center
  top: 0

.card-info-block
  box-shadow: 5px 6px 50px #F2F2F2
  background-color: #fff
  border-radius: 20px
  min-height: 400px
  padding: 108px 27px 30px 27px

.card-title-block
  width: 100%
  margin: 0 0 24px 0
  .card-title
    @include modText(20px, 30px, #2E2E2E, 600, null)

.card-info
  @include modText(15px, 30px, #636363, 400, null)

.system-area
  padding: 64px 0 83px 0

.system-info-area
  display: flex
  justify-content: space-between

.system-img-area
  width: 510px
  position: relative
  .system-img
    position: absolute
    top: -10px
    z-index: 2
  .system-img-dots
    z-index: 1
    position: absolute
    bottom: 30px
    left: -140px

.system-list
  max-width: 490px
  position: relative
  z-index: 5

.system-item
  position: relative
  margin: 0 0 38px 0
  padding: 0 0 0 51px

.check-block
  position: absolute
  top: 0
  left: 0

.system-title
  margin: 0 0 8px 0
  @include modText(20px, 27px, #2E2E2E, 600, null)

.system-info
  letter-spacing: -0.02em
  @include modText(15px, 20px, #636363, 400, null)

.system-sound-area
  display: flex
  flex-direction: column

.system-sound-title
  padding: 0 0 0 51px
  margin: 0 0 24px 0
  @include modText(20px, 27px, #2E2E2E, 600, null)

.system-player
  max-width: 448px
  width: 100%
  background-color: #FFFFFF
  box-shadow: 0px 4px 10px #E2E2E2
  border-radius: 20px
  display: flex
  align-items: center
  padding: 10px 15px
  margin: 0 0 30px 0

.player-play
  width: 14px
  height: 100%
  display: flex
  align-items: center
  margin: 0 9px 0 0
  &:hover
    cursor: pointer

.sound-name
  width: 120px
  margin: 0 5px 0 0
  @include modText(15px, 20px, #3A3A3A, 400, null)

.player-next
  margin: 0 15px 0 15px
  height: 100%
  &:hover
    cursor: pointer

.player-time
  opacity: 0.6
  @include modText(12px, 12px, #3A3A3A, 400, null)

.order-system-area
  position: relative
  display: flex
  align-items: center
  .btn
    text-transform: inherit
    margin: 0 55px 0 0
    height: 47px
    @include modText(17px, 23px, #303030, 600, null)
    svg
      margin: 0 0 0 22px

.call-area
  position: absolute
  top: -63px
  right: 273px
  z-index: 50
  &:hover
    cursor: pointer

.call-filter
  border-radius: 20px
  position: absolute
  background: #0E63DC
  filter: blur(10px)
  width: 140px
  height: 68px
  z-index: 0
  left: -10px
  bottom: -10px
  opacity: 0.1

.call-block
  background-color: #FFFFFF
  box-shadow: inset 0px 4px 20px rgba(255, 255, 255, 0.3)
  border-radius: 20px
  padding: 17px 20px
  width: 340px
  display: flex
  flex-wrap: wrap
  position: relative
  z-index: 2


.call-img-area
  position: relative
  width: 58px
  height: 58px
  display: flex
  justify-content: center
  align-items: center
  background: #ECF3FC
  border-radius: 12px
  margin: 0 24px 0 0


.call-text
  margin: 0 0 7px 0
  @include modText(14px, 19px, #636363, 400, null)
.call-text-bold
  @include modText(18px, 25px, #2E2E2E, bold, null)

.order-price
  @include modText(27px, 37px, #636363, 400, 600)
  span
    color: #FF3F02

.publication-area
  padding: 43px 0 45px 0

.publication-list
  display: flex

.publication-item-area
  flex: 1 1 auto
  margin: 0 0 10px
  display: flex
  justify-content: center

.publication-item
  width: 282px
  display: flex
  flex-direction: column

.publication-item-img
  margin: 0 0 30px 0

.publication-item-title
  min-height: 54px
  margin: 0 0 8px 0
  @include modText(20px, 27px, #2E2E2E, 600, null)

.publication-item-text
  letter-spacing: -0.02em
  @include modText(15px, 20px, #636363, 600, null)

.application-area
  //padding-left: 0
  //padding-right: 0

.slider-application
  //.slick-track
  //  padding: 0 50px
  //.slick-list
  //  padding: 0 0px 30px 0px
  & + .button-area
    margin: 0 0 0 0
.slide-app-block
  margin: 0 12px 40px 12px
  display: block
  max-width: 282px
  border-radius: 12px
  background-color: #fff
  box-shadow: 5px 6px 50px #f2f2f2
  overflow: hidden

.app-text
  padding: 1rem
  padding-top: 0.4rem
  @include modText(20px, 27px, #2E2E2E, 600, null)

.contact-area
  padding: 100px 0 70px 0
  display: flex
  justify-content: space-between
  .title-block
    margin: 0 0 60px 0
    padding: 0
    .title
      @include modText(40px, 45px, #303030, 600, null)

.contact-info
  .top-line
    .title:after
      left: 0

.contact-info-text
  @include modText(18px, 28px, #2C2E33, 300, null)
  margin: 0 0 70px 0

.contact-ways,.contact-way
  display: flex
  align-items: center

.contact-way
  a
    margin: 0 0 0 20px
    white-space: nowrap

.contact-way + .contact-way
  margin: 0 0 0 76px

.way-phone
  a
    @include modText(18px, 28px, #2C2E33, 300, 400)

.way-email
  a
    text-decoration-line: underline
    @include modText(18px, 28px, #0E63DC, 300, nulll)
    &:hover
      color: #0A55C1
.contact-form-area
  width: 100%
  max-width: 535px
  margin: 0 0 0 20px
.contact-form
  width: 100%
  .form-flex
    margin: 0 0 35px 0
    .input-container + .input-container
      margin: 0 0 0 20px
  .label
    @include modText(20px, 31px, #2C2E33, 400, nulll)
    margin: 0 0 5px 0

  .input-area
    textarea,
    input
      border-radius: 0
      padding: 0
      border: none
      border-bottom: 1px solid #2C2E33
    textarea
      height: 100px
  .button-area
    margin: 50px 0 0 0
    button
      text-transform: inherit
      padding: 0 10px
      height: 60px
      width: 257px
      @include modText(18px, 25px, #303030, 600, nulll)
  .checkbox-area
    .label
      margin: 0 10px 0 0
    .checkbox-text
      @include modText(13px, 17px, #101216, 400, nulll)
      span
        // margin: 0 0 0 5px
        text-decoration-line: underline

.form-flex
  display: flex

.system-list-slider
  display: none

.contact-info
  .title-home.title-block
    justify-content: flex-start


.footer
  background-color: #303030

.footer-area
  padding: 30px 0
  margin: 0 -5px

.footer-nav-area
  display: flex
  align-items: flex-start
  justify-content: space-between

.footer-nav-block
  margin: 0 5px 20px 5px
  a,p
    letter-spacing: -0.01em
    cursor: pointer
    @include modText(18px, 27px, #fff, 400, nulll)

.soc-area
  margin: 2px 0 0 15px
  display: flex
  justify-content: center
  svg
    cursor: pointer
  svg + svg,
  a + a
    margin: 0 0 0 24px

.footer-soc
  display: flex
  align-items: center

.modal-close
  position: absolute
  top: 30px
  right: 20px
  width: 14px
  height: 14px
  cursor: pointer

.modal.modal-agreement
  width: 100%
  height: auto!important
  overflow-y: auto
  padding: 34px 25px 47px 31px
  .modal-title-block
    padding: 0
    .modal-title
      text-transform: uppercase
      @include modText(20px, 27px, #2A2B31, 600, null)
  .modal-text
    //max-height: 100%
    //overflow-y: auto
    margin: 0 10px 0 0
    @include modText(13px, 18px, #101216, 400, null)

.modal-agreement
  width: 100%
  max-width: 690px

.ReactModal__Overlay
  padding: 10px

.system-player
  position: relative
  box-shadow: 0px 4px 10px #e2e2e2
  .rhap_container
    padding: 0
    margin-left: auto
    //position: absolute
    background-color: #ffffff
    box-shadow: none
    width: 50%
    left: 50px
  .rhap_controls-section
    display: none
  .rhap_time
    @include modText(13px, 15px, #3A3A3A, 400, null)
  .rhap_progress-bar
    height: 4px
  .rhap_progress-indicator
    background: rgb(255, 205, 6)
    width: 12px
    height: 12px
    top: -4px
  .rhap_current-time
    display: none
  .rhap_progress-container
    padding: 0 5px

.system-item-img
  height: 100%
  display: flex

.system-img-mini-area
  display: none
  justify-content: center
  margin: -50px 0 5px 0

.burger
  display: flex

  height: 30px
  width: 40px

  flex-direction: column
  justify-content: space-between
  align-items: center
  padding: 3px 2.5px
  border: 0
  background-color: transparent
  cursor: pointer

.burger span
  width: 100%
  height: 4px
  border-radius: 5px
  background-color: #FFCC00

.burger:hover span
  background-color: #FFCC00

.burger--active span:nth-child(2)
  display: none

.burger--active span:nth-child(1)
  transform: rotate(45deg) translate(8px, 7px)

.burger--active span:nth-child(3)
  transform: rotate(-45deg) translate(9px, -8px)

.banner__back
  margin-top: 1rem

.price-table
  margin: 2rem auto 3rem
  display: table
  border-radius: 16px
  overflow: hidden
  border: 2px solid #FFCC00

.price-tr
  display: table-row

.price-th
  background-color: #FFCC00
  color: #000
  display: table-cell
  padding: 0.9rem
  font-weight: 600
  font-size: 1.1rem
  text-align: center

.price-td
  display: table-cell
  text-align: center
  padding: 0.9rem
  font-weight: 600
  font-size: 1.1rem

.price-tr:nth-of-type(n)
  background-color: #fff

.price-tr:nth-of-type(2n)
  background-color: #F5F5F5

.prices-color
  background-color: #ECF3FC !important