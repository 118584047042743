$var1: #ffffff
$var2: #bada55

.switch-area
  display: flex


input[type=checkbox]
  height: 0
  width: 0
  visibility: hidden

.radio
  cursor: pointer
  text-indent: -9999px
  width: 50px
  height: 1.9rem
  background: #fff
  display: block
  border: 2px solid #979797
  border-radius: 100px
  position: relative
  &:focus
    outline: none
  &:after
    content: ''
    position: absolute
    top: 3px
    left: 3px
    width: 20px
    height: 20px
    background: #D6D6D6
    border-radius: 90px
    transition: 0.2s
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)

input:checked + .radio
  &:after
    background: #FFCC00
    left: calc(100% - 3px)
    transform: translateX(-100%)

input:disabled:checked + label
  &:after
    background: #D6D6D6
    left: calc(100% - 3px)
    transform: translateX(-100%)
