@import "button"
@import "input"
@import "switch"
@import "checkbox"
@import "days"

::-webkit-scrollbar
  width: 4px

::-webkit-scrollbar-track
  background: transparent

::-webkit-scrollbar-thumb
  background-color: #D0D0D0
  border-radius: 5px

@media (prefers-reduced-motion: no-preference)
  :root
    scroll-behavior: smooth

@font-face
  font-family: "Inter"
  font-style: "normal"
  font-weight: 300
  src: url(../fonts/Inter-Light.woff2) format('woff2')
  font-display: swap

@font-face
  font-family: "Inter"
  font-style: "normal"
  font-weight: 400
  src: url(../fonts/Inter-Regular.woff2) format('woff2')
  font-display: swap

@font-face
  font-family: "Inter"
  font-style: "normal"
  font-weight: 600
  src: url(../fonts/Inter-SemiBold.woff2) format('woff2')
  font-display: swap

@font-face
  font-family: "Inter"
  font-style: "normal"
  font-weight: 600
  src: url(../fonts/Inter-Bold.woff2) format('woff2')
  font-display: swap

*
  box-sizing: border-box
  touch-action: manipulation

p
  padding: 0
  margin: 0

html
  width: 100%
  overflow-x: hidden

body
  overflow-x: hidden
  height: 100%
  width: 100%
  color: #303030
  font-size: 16px
  font-family: Inter, Arial, serif
  line-height: 1.4

a
  color: inherit
  text-decoration-line: none

#root
  height: 100%

.main-area-field
  width: 100%
  display: flex
  justify-content: center
  z-index: 1
  &.full-height
    height: 100vh

.main-area
  position: relative
  // max-width: 768px
  width: 100%
  &.p-15
    padding: 0 15px
  &.full-height
    height: 100vh

.page-area
  position: relative
  flex-wrap: wrap
  display: flex
  width: 100%
  min-height: 100%
  background-color: #F3F3F3
  // padding: 25px 15px 56px 15px
  align-items: center
  flex-direction: column
  margin: 0 auto
  padding-bottom: 2rem
  &.no-p
    padding-left: 0
    padding-right: 0
  &.no-p-top
    padding-top: 0
  &.color-white
    background-color: #fff
  &.no-padding-l-r
    padding-right: 0
    padding-left: 0
  &.center-h
    // align-items: center
    // height: calc(100% - 36px)
    // padding: 0
    // flex-direction: row
    // width: 100%
    // justify-content: center
  &.center-v
    justify-content: center
  
  .logs-container
    position: absolute
    top: 52px
    left: 0
    right: 0
    display: flex
    align-items: center
    justify-content: flex-end
    padding: 3px
    z-index: 1
    .btn-yellow-border
      font-size: 10px
      line-height: 14px
      min-height: 28px

._no-signal
  background-image: url("../images/pic-no-signal.jpg")
  width: 100%
  height: 100%
  background-size: cover
  background-position: center
  background-repeat: repeat

._signal
  background-image: url("../images/pic-no-signal.jpg")
  // width: 100%
  // height: 100%
  background-size: cover
  background-position: center
  background-repeat: no-repeat

.content
  max-width: 340px
  width: 100%
  padding: 0 15px 15px
  display: flex
  flex-direction: column
  align-items: center
  height: calc(100% - 97px)
  // margin-top: 54px

.title-block
  width: 100%
  display: flex
  align-items: center
  // max-width: 640px
  .title
    position: relative
    text-transform: uppercase
    @include modText(11px, 29px, #303030, 600, null)
  &--wide
    max-width: none

.enter-window
  position: relative
  max-width: 320px
  width: 100%
  box-shadow: $shadow
  border-radius: 12px
  padding: 2rem 1rem
  background-color: #fff
  .btn
    width: 100%
    min-height: 40px
    font-size: 1rem
    text-transform: uppercase
  .btn + .btn
    margin: 15px 0 0 0

.close
  width: 40px
  height: 40px
  position: absolute
  top: -50px
  right: 0px
  border-radius: 100%
  box-shadow: $shadow
  display: flex
  justify-content: center
  align-items: center
  background-color: #fff
  &:hover
    cursor: pointer

.back-link-block
  width: 100%
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center

.back-link
  display: flex
  align-items: center
  margin: 0 0 35px 0
  .back-text
    @include modText(17px, 23px, #2A2B31, 600, null)
  .back-icon
    margin: 0 7px 0 0

.not-found-block
  display: flex
  flex-direction: column
  align-items: center

.not-found-text
  text-align: center
  @include modText(28px, 38px, #303030, 600, null)
  margin: 0 0 50px 0

.not-found-icon
  margin: 0 0 50px 0

.not-found-info
  text-align: center
  @include modText(18px, 23px, #2A2B31, 400, null)

.enter-form-area
  width: 100%
  max-width: 320px
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2)
  padding: 1.5rem
  background-color: #fff
  border-radius: 16px
  .title-form-block
    margin: 0 0 20px 0

.title-form-block
  width: 100%
  .title
    @include modText(16px, 20px, #2A2B31, 600, null)
  .sub-title
    margin-top: 10px
    @include modText(14px, 15px, #303030, 400, null)

.enter-form-area
  .button-area
    margin: 17px 0 0 0
  .forgot-password
    @include modText(11px, 15px, #2A2B31, 400, null)
    text-transform: uppercase
  .btn-enter
    font-size: 1rem
    padding: 0.5rem 2rem

.registration-form-area
  box-shadow: none
  width: 100%
  padding: 0 15px
  margin: 0 0 60px 0
  // .input-full-name, .input-phone, .code
  .input-full-name, .input-phone
    margin: 0 0 23px 0
  .btn-border
    min-height: 27px
    max-width: 153px
    width: 100%
  .code-icon
    position: absolute
    right: -22px
  .button-area
    width: auto
    margin: 0 15px 15px 15px
  .id-device
    margin: 23px 0 12px 0
  .btn-yellow
    text-transform: inherit
    height: 41px
    font-weight: 600
    width: 100%

form
  .form-eye
    user-select: none
    padding: 0 10px
    cursor: pointer
    position: absolute
    top: 1px
    right: 3px
    height: 36px
    width: 36px
    padding: 0.5rem
    display: flex
    align-items: center
    justify-content: center
    background-color: transparent
    border-radius: 50%
    svg
      width: 30px
      height: 20px
    &:focus
      outline: 2px solid #000

.code-device
  margin: 0 0 30px 0
  input
    border: none
    background-color: #EAE9E9
    text-transform: uppercase
    @include modText(1rem, 18px, #303030, 600, null)

.id-device
  @include modText(13px, 18px, #303030, 600, null)

.chimes-content
  max-width: 320px
  width: 100%
  margin: 0 0 50px 0
  .chimes-save
    width: 100%
    padding: 11px 15px

.chimes-item
  padding: 0.6rem 0.5rem
  background-color: #eee
  border-radius: 16px
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px
  // &--active
  //   outline: 2px solid #FFCC00

.chimes-item + .chimes-item
  margin-top: 0.8rem

.chimes-time
  display: flex
  align-items: center
  justify-content: space-between
  margin: 0 0 5px 0
  .time
    width: 100%
    @include modText(1rem, 18px, #565555, 600, null)
    padding: 0.5rem 10px
    border-radius: 16px
    &:hover
      cursor: default
  .switch-area
    margin: 0 0 0 10px

.chimes-options:not(.input-link)
  display: flex
  margin-top: 0.2rem
  input
    border-color: #979797
    padding-right: 28px
  a
    width: 100%
  .custom-select-area
    margin: 0 0 0 20px
    width: 100%

.sleep-mode
  margin: 25px 0 30px 0
  padding: 0 10px
  background-image: url("../images/sleep-mode.png")
  width: 100%
  height: 97px
  background-position: center
  border-radius: 8px
  display: inline-block
  background-size: cover
  //overflow: hidden
  //background-size: cover
  .mode-header
    margin: 17px 0 10px 0
    display: flex
    justify-content: center
    align-items: center
    .text
      text-transform: uppercase
      @include modText(13px, 13px, #fff, 600, null)
    .switch-area
      margin: 0 0 0 10px


.custom-select-area.mini
  width: 45px
  margin: 0 0 0 4px
  .custom-select,.custom-select-options
    padding: 4px 5px

.mode-time
  display: flex
  justify-content: space-between
  align-items: center

  .t
    text-transform: uppercase
    @include modText(13px, 15px, #fff, 600, null)

.signal-info
  margin: 12px 0
  text-align: center
  @include modText(13px, 18px, #303030, 400, 600)

.signal-bold
  margin: 40px 0
  text-align: center
  @include modText(24px, 33px, #2A2B31, 400, 600)

.options-bells
  justify-content: center
  position: absolute
  .button-area
    margin: 20px 0
  .btn
    width: 100%
    padding: 0.5rem 2rem
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2)

.block
  width: 100%
  background: #FFFFFF
  box-shadow: 0px 4px 8px rgba(33, 33, 33, 0.16)
  border-radius: 8px
  margin: 0 0 22px 0

.block + .button-area
  button
    width: 100%
.setting-block
  padding: 14px 20px
.trans-block
  padding: 0
  background-color: transparent
  box-shadow: none

.setting-link
  min-height: 52px
  border-radius: 30px
  padding: 14px 10px
  transition: $transition
  display: flex
  align-items: center
  &:hover
    cursor: pointer
    background-color: #F4F4F4
    .setting-text
      text-shadow: 0 0 1px
    .setting-icon
      path
        transition: $transition
        fill: #FFCC00
.icon-area
  width: 22px
  height: 22px
  display: flex
  align-items: center
  justify-content: center
  margin: 0 10px 0 0
  position: relative
  .icon-time
    left: -2px

.setting-icon
  position: absolute
  width: 20px
  height: 20px

.setting-text
  position: relative
  transition: $transition
  text-transform: uppercase
  @include modText(16px, 25px, #303030, 600, null)

.under-title
  @include modText(17px, 23px, #2A2B31, 600, null)
  max-width: 640px
  &--home
    padding: 2rem
  .icon
    margin: 0 8px 0 0

.force-block
  padding: 25px 20px

.force-list
  max-height: 265px
  overflow-y: auto
  padding: 3px 0
  .force-item:last-child
    margin: 0

.force-item
  display: flex
  align-items: center
  margin: 0 0 15px 0

.force-num
  margin: 0 15px 0 0
  @include modText(15px, 20px, #636363, 600, null)

.force-count-area
  min-height: 25px
  display: flex
  align-items: center
  background: #FFFFFF
  box-shadow: 0px 2px 8px rgba(33, 33, 33, 0.05)
  border-radius: 8px
  .dec, .inc, .count
    user-select: none
    padding: 0 13px
    display: flex
    align-items: center
    justify-content: center
    letter-spacing: -0.02em
    @include modText(18px, 25px, #2A2B31, 600, null)
  .dec
    cursor: pointer
    border-right: 1px solid #D0D0D0
  .inc
    cursor: pointer
    border-left: 1px solid #D0D0D0
  .count
    min-width: 46px
    position: relative
    padding: 0 3px

.general-block
  padding: 16px 20px 63px 20px
  .code-device
    margin: 0

  &.block-loading
    position: relative
    z-index: 1
    &::before
      position: absolute
      content: ''
      left: 0
      right: 0
      top: 0
      bottom: 0
      background-color: #fff
      z-index: 2
      opacity: 0.5
      animation: opacity-glow 2s infinite

@keyframes opacity-glow
  0%
    opacity: 0.6
  50%
    opacity: 0.8
  100%
    opacity: 0.6

.general-title
  @include modText(14px, 19px, #636363, 600, null)
  margin: 0 0 17px 0
  &._mr
    margin: 0 0 11px 0

.general-item
  margin: 0 0 17px 0
  display: flex
  justify-content: center
  padding: 0 10px 17px 10px
  // padding: 0 10px 20px 10px
  // border-bottom: 1px solid #CBCBCB

.ringing-area
  padding: 20px 11px 25px 14px

.ringing-scroll
  max-height: 320px
  overflow-y: auto
.ringing-item:last-child
  border-bottom: none

.ringing-title
  margin: 0 15px 5px 15px
  .ringing-name
    text-transform: uppercase
    font-weight: normal

.ringing-item
  position: relative
  border-bottom: 1px solid #CBCBCB
  padding: 4px 0 4px 0
  margin: 0 15px 0 15px
  &:hover, &._selected
    .ringing-name
      font-weight: bold
      color: #303030
      cursor: pointer
      background: #F4F4F4
      &:before
        transition: $transition
        opacity: 1

.ringing-name
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  transition: $transition
  @include modText(1rem, 20px, #636363, 600, null)
  position: relative
  background: #fff
  border-radius: 0 30px 30px 0
  padding: 8px 15px 8px 0
  text-align: left
  &:before
    opacity: 0
    transition: $transition
    height: 100%
    content: ''
    left: -15px
    background: #F4F4F4
    width: 15px
    position: absolute
    top: 0
    border-radius: 30px 0 0 30px

.drop-calendar-area
  width: 307px
  height: auto
  background-color: #fff
  padding: 11px 8px 12px 15px

.calendar-header-block
  display: flex
  align-items: center
  justify-content: space-between
  margin: 0 0 22px 0

.calendar-date
  @include modText(18px, 25px, #2A2B31, 400, 600)

.calender-nav
  .calendar-icon:last-child
    margin: 0 0 0 15px
.calendar-icon
  cursor: pointer
  path
    transition: $transition
    fill: #000
  &:hover
    path
      fill: #cccccc

.calendar
  display: flex
  flex-direction: column

.calendar-row
  width: 100%
  display: flex

.calendar-day, .calendar-number
  width: 40px
  height: 40px
  display: flex
  align-items: center
  justify-content: center
  text-transform: uppercase
  @include modText(12px, 15px, #2A2B31, 600, null)

.calendar
  ._row-days
    .calendar-number
      border-top: solid 1px #d9d9d9
.calendar-row
  .calendar-number:first-child
    border-left: solid 1px #d9d9d9

.calendar-number
  font-weight: 400
  border-right: solid 1px #d9d9d9
  border-bottom: solid 1px #d9d9d9
  background-color: #FFFFFF
  transition: $transition
  &:hover
    cursor: pointer
    background-color: #FFCC00

.custom-select-area
  cursor: pointer
  width: 88px
  &._open
    .select-icon
      transform: rotate(180deg)
    .custom-select
      border-radius: 3px 3px 0 0
      border-bottom: none
    .custom-select-options
      top: -1px
      display: block
      border-right: 1px solid #979797
      border-left: 1px solid #979797
      border-bottom: 1px solid #979797
      span:first-child
        border-top: 1px solid #CBCBCB

.custom-select
  padding: 4px 10px 4px 10px
  display: flex
  align-items: center
  justify-content: space-between
  height: 100%
  background-color: #FFFFFF
  border: 1px solid #979797
  border-radius: 3px
  span
    @include modText(13px, 18px, #636363, 600, null)

.custom-select-result
  overflow: inherit
  position: relative

.custom-select-options
  display: none
  user-select: none
  z-index: 100
  position: absolute
  background-color: #FFFFFF
  width: 100%
  max-height: 150px
  overflow: auto
  padding: 0 10px
  border-radius: 0 0 3px 3px
  span:last-child
    border-bottom: none
  span
    width: 100%
    border-bottom: 1px solid #CBCBCB
    @include modText(13px, 18px, #716F69, 400, null)
    cursor: pointer
    padding: 5px 0
    display: inline-block
    white-space: nowrap
    text-overflow: ellipsis

.icon-1, .icon-2, .icon-3, .icon-4, .icon-5
  background-position: center
  background-repeat: no-repeat
  background-size: cover

._active
  width: 100%
  height: 100%
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  border-bottom: none
  // filter: drop-shadow(0px 4px 8px rgba(33, 33, 33, 0.16))
  box-shadow: 0px 4px 8px rgba(33, 33, 33, 0.16)
  &.icon-1
    background-image: url("../../assets/images/timers-icon-1.png")
  &.icon-2
    background-image: url("../../assets/images/timers-icon-2.png")
  &.icon-3
    background-image: url("../../assets/images/timers-icon-3.png")
  &.icon-4
    background-image: url("../../assets/images/timers-icon-4.png")
  &.icon-5
    background-image: url("../../assets/images/timers-icon-5.png")

  .mode-timers
    border-bottom: none

.chimes-item--active
  &.icon-2
    background-image: url("../../assets/images/timers-icon-2.png")
  &.icon-3
    background-image: url("../../assets/images/timers-icon-3.png")
  &.icon-4
    background-image: url("../../assets/images/timers-icon-4.png")
  &.icon-5
    background-image: url("../../assets/images/timers-icon-5.png")

.timers-item
  border-radius: 16px
  padding: 0.6rem 0.5rem
  margin: 0 0 10px 0
  border-bottom: none
  opacity: 1
  &._active
    margin-bottom: 11px
    // outline: 2px solid #FFCC00
  &:last-of-type._active
    margin-bottom: 31px

.timers-list
  margin: 0 0 30px 0

.mode-timers
  width: 100%
  padding: 0 0 0.7rem 0
  .custom-select-area
    // flex: 0 0 111px
    margin: 0
    width: 100%

.custom-select-area
  position: relative
  .select-icon._abs
    position: absolute
    right: 10px
    top: 14px
    pointer-events: none
    transition: 0.2s transform ease
    &._rotate
      transform: rotate(180deg)

.new-devices
  margin-top: 20px
  max-height: 25px
  overflow: hidden
  transition: 0.2s max-height ease
  &._open
    max-height: 270px
  .new-devices-title
    font-size: 13px
    color: #303030
    font-weight: 600
    display: flex
    align-items: center
    &:hover
      cursor: pointer
    .arrow
      margin-right: 10px
      transition: 0.2s transform ease
      &._rotate
        transform: rotate(180deg)
  .id-device
    margin: 23px 0 12px 0
  .button-area
    .btn
      @include modText(13px, 17px, #303030, 600, null)
      width: 120px

.settings-item
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 17px
  padding-bottom: 17px
  .general-title
    margin: 0
    &:hover
      cursor: pointer
  .settings-value
    @include modText(14px, 19px, #636363, 400, null)

.devices-content
  max-width: 320px
  width: 100%
  margin: 0 0 50px 0

.device-area
  z-index: 1
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  &:hover
    cursor: pointer
    .devices-dots
      svg
        path
          fill: #6D6D6D

.device-name
  padding: 12px
  @include modText(16px, 22px, #2A2B31, 600, null)
  span._me
    font-weight: 600

.devices-dots
  position: relative
  z-index: 100
  padding: 13px 10px 13px 0
  width: 40px
  height: inherit
  display: flex
  align-items: center
  justify-content: flex-end
  &:hover
    cursor: pointer
  svg
    path
      fill: #9F9F9F

textarea
  resize: none
.device-wrapper
  position: relative
  background: #e3e3e3
  border-radius: 10px
  margin-bottom: 0.8rem
  box-shadow: 0px 4px 8px rgba(33, 33, 33, 0.16)
.dots-menu-area
  z-index: 100
  padding: 13px 24px 13px 11px
  position: absolute
  right: 0
  top: 0
  background-color: #FFFFFF
  width: 100%
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
  border-radius: 10px
  textarea
    height: 46px
  .btn
    height: 30px
    @include modText(10px, 14px, #2A2B31, bold, null)
  .btn-red
    color: #f00
    margin-top: 10px
    margin-bottom: -10px
    padding-left: 5px
    background-color: #fff
    &:hover, &:active
      color: #c90202
  .btn-code
    color: #2A2B31
    margin-top: 0px
    margin-bottom: -10px
    padding-left: 5px
    background-color: #fff
    font-size: 12px

  .devices-dots
    position: absolute
    top: 3px
    right: 0
.error-message
  background-color: #FFFFFF
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
  border-radius: 10px
  padding: 12px 11px 14px 11px
  max-width: 290px
  width: 100%
  margin: 20px 0

.error-message-text
  @include modText(16px, 21px, #2A2B31, 400, null)

.bell-item
  margin: 0 0 22px 0
  position: relative
  z-index: 1
  &.bell-item-thin
    padding-left: 15px
    padding-right: 15px
    &::before
      left: 15px
      right: 15px
  &::before
    position: absolute
    z-index: -1
    content: ''
    height: 1px
    left: 0
    right: 0
    bottom: -11px
    background-color: #CBCBCB
  &:last-of-type
    &::before
      display: none
  .bell-name
    font-size: 15px
    line-height: 20px
    color: #636363
    font-weight: 600
    position: relative
    z-index: 1
    &::after
      position: absolute
      content: ''
      left: -15px
      right: -15px
      bottom: -7px
      top: -7px
      background: #F4F4F4
      border-radius: 30px
      z-index: -1
      display: none
    &:hover
      cursor: pointer
      &::after
          display: block
  .bell-block
    display: none
    font-size: 13px
    line-height: 15px
    color: #636363
    font-weight: 400
    padding: 15px 0 0px
    .bell-song
      font-size: 13px
      line-height: 20px
      color: #636363
      font-weight: 400
  &.bell-item-active
    .bell-name
      color: #303030
      &::after
        display: block
    .bell-block
      display: block
  .bell-controls
    display: flex
    align-items: center
    justify-content: center
    margin-top: 10px
    .bell-btn
      color: #303030
      font-size: 12px
      text-transform: uppercase
      border-radius: 30px
      padding: 8px 15px
      display: flex
      align-items: center
      justify-content: center
      span
        display: inline-block
        margin-left: 7px
        margin-top: 3px
      &:hover
        background: #F4F4F4
        cursor: pointer

.system-player.bells-player
  margin: 0
  box-shadow: none
  padding: 5px 0 15px
.system-player.bells-player .rhap_container
  width: 225px
  
.bell-settings
  padding: 10px 20px
  border-radius: 8px
  background-color: #fff
  margin-bottom: 1rem
  box-shadow: 0px 4px 8px rgba(33, 33, 33, 0.16)
  &__title
    font-size: 14px
    line-height: 19px
    color: #303030
    font-weight: 600
    margin-bottom: 0.5rem

.bellsettings-item
  display: flex
  align-items: center
  justify-content: space-between
  min-height: 25px
  margin-bottom: 0.4rem

.normal-title
  font-size: 13px
  line-height: 19px
  color: #636363
  font-weight: 600

._w90
  width: 90px

._w106
  width: 106px

._w130
  width: 130px

.input-area__hovered
  input
    &:hover
      cursor: pointer

.title-form
  display: flex
  align-items: center
  justify-content: center
  gap: 5px
  width: 100%
  margin-bottom: 5px
  &+&
    margin-top: 5px

.input-title
  border: 1px solid #979797
  font-size: 13px
  line-height: 18px
  color: #303030
  font-weight: 600
  border-radius: 3px
  padding: 3px 10px
  width: 100%

.btn-save-title
  font-size: 12px
  line-height: 18px
  font-weight: 600
  border-radius: 50px
  padding: 3px 10px
  min-height: auto
  background: #FFCC00
  color: #303030
  width: 50%
  &--reset
    background: #e3e3e3

.melodies-settings
  padding: 10px 20px
  border-radius: 8px
  background-color: #fff
  margin-bottom: 1rem
  box-shadow: 0px 4px 8px rgba(33, 33, 33, 0.16)
  display: flex
  flex-direction: column

.melody-count-buttons
  display: flex
  align-items: center
  justify-content: flex-end
  margin-bottom: 10px
  min-height: 36px

.input-area input.main-bell
  text-align: center
  padding: 0.5rem 0.8rem