$transition: 0.2s ease all

$colorTextBlue: #00718B
$colorTextBlue2: #85C4C9
$color: #F8EFEF
$color2: #AB2525
$color3: #0BA85D
$color4: #EFF8F6
$color5: #F3F3F3
$color6: #676F73
$color7: #A8DBD9
$shadow: 0px 4px 8px rgba(33, 33, 33, 0.16)

$gradient: linear-gradient(180deg, #00718B 27.55%, #46ADA0 98.4%)

@mixin modText ($fs,  $lh,  $col, $fw, $s_fw)
  font-size: $fs
  color: $col
  font-weight: $fw
  span
    font-weight: $s_fw
