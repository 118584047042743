@import "vars"

.input-link
  cursor: pointer
  width: 100%
  display: inline-block
  input
    cursor: pointer

.input-container
  &._no-mr
    margin: 0
    position: relative
    .select-icon._abs
      position: absolute
      right: 10px
      top: 16px
      pointer-events: none

.input-container
  width: 100%
  margin: 0 0 10px 0
  .input-area
    width: inherit
    position: relative
    display: flex
    flex-wrap: wrap
    align-items: baseline
    min-height: 27px
  .input-error
    input
      border: 1px solid #d22518
      color: #d22518
    textarea
      border: 1px solid #d22518
      color: #d22518
.error
  width: 100%
  margin: 2px
  color: #d22518
  font-size: 13px
  line-height: 18px

.input-area input::placeholder,
.input-area textarea::placeholder
  @include modText(16px, 14px, #737373, 400, null)

.input-area input,
.input-area input:disabled,
.input-area input[disabled],
.input-area select,
.input-area textarea
  opacity: 1
  padding: 0.5rem 2.6rem 0.5rem 0.8rem
  background: #fff
  border: 2px solid #979797
  border-radius: 12px
  width: inherit
  margin: 0
  @include modText(16px, 18px, #303030, 400, null)
