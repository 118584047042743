.week-container
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  padding: 0 0 6px 0
  .week-day
    background: #fff
    outline: 0
    border: 2px solid #979797
    padding: 3px 5px
    color: #636363
    font-size: 13px
    font-weight: 600
    border-radius: 6px
    &:hover
      cursor: pointer
    &--active
      background-color: #FFCC00
      color: #fff

._active
  .week-container
    border-bottom: none

.time-time
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 5px
  .time-title
    font-size: 0.85rem
    font-weight: 400
    margin-right: 0.5rem
    color: #565555
    width: 100%
    padding: 0.4rem 10px
    border-radius: 16px
    &--active
      font-weight: 600

.silence-mode
  margin: 25px 0 0px 0
  padding: 0 10px 15px
  background-color: #eee
  width: 100%
  min-height: 97px
  background-position: center
  border-radius: 8px
  display: flex
  flex-direction: column
  align-items: center
  background-size: cover
  .mode-header
    margin: 17px 0 10px 0
    display: flex
    justify-content: center
    align-items: center
    .text
      text-transform: uppercase
      @include modText(0.8rem, 13px, #000, 600, null)

.silence-items
  .silence-item
    position: relative
    opacity: 1
    padding: 0.2rem 1rem
    background: #fff
    outline: 0
    border: 2px solid #979797
    border-radius: 16px
    width: inherit
    font-size: 1rem
    color: #303030
    font-weight: 600
  .input-area
    position: relative
    & + .input-area
      margin-top: 0.3rem
    .delete-silence
      position: absolute
      top: 8px
      right: -1.7rem
      height: 16px
      width: 16px
      &:hover
        cursor: pointer
      span
        position: absolute
        top: 2px
        left: 0
        height: 100%
        width: 2px
        border-radius: 4px
        background-color: #979797
        &:nth-child(1)
          left: 5px
          transform: rotate(45deg)
        &:nth-child(2)
          left: 5px
          transform: rotate(-45deg)
  .input-area .btn
    width: 100%
    font-size: 0.9rem
    font-weight: 600
    text-transform: capitalize
    margin-top: 0.8rem
  .input-area input
    text-align: center
    margin-bottom: 0.4rem
    width: 140px
    font-weight: 400
    color: #636363
    &:hover
      cursor: pointer
  .input-area input.--active
    font-weight: 600

.time__select
  position: relative
  .select-icon
    top: 10px
    right: 10px
    position: absolute
    &._abs
      top: 16px
  &:hover
    cursor: pointer

.chimes-time .time.time__select
  &:hover
    cursor: pointer