@import "../../assets/styles/vars"

.ReactModal__Overlay
  background-color: rgba(0, 0, 0, 0.5) !important
  z-index: 99 !important


.modal
  position: relative
  width: 290px
  height: auto
  top: 50%
  left: 50%
  right: auto
  bottom: auto
  margin-right: -50%
  transform: translate(-50%, -50%)
  background: #FFFFFF
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
  border-radius: 10px
  padding: 18px 0 30px 0
  .button-area
    padding: 0 20px
  &.modal-ringing
    padding: 0

.modal-title-block
  padding: 0px 20px 0 20px
  display: flex
  align-items: center
  width: 100%
  margin: 0 0 11px 0
.modal-title
  @include modText(16px, 22px, #303030, 600, null)

.modal-time
  padding: 0 20px
  margin: 0 0 25px 0

.time-name
  text-transform: uppercase
  @include modText(11px, 15px, #636363, 400, null)
  margin: 0 0 10px 0

.time-settings
  display: flex
  align-items: center
  justify-content: space-between

.time-settings-block
  display: flex
  align-items: center
  .custom-select-area
    width: 102px
  .t
    margin: 0 6px 0 0
    @include modText(11px, 15px, #636363, 400, null)
    text-transform: uppercase

.modal-ringing
  .button-area
    margin: 5px 0 0 0
    button
      max-width: 117px
      width: 100%
      @include modText(13px, 18px, #303030, 600, null)
