.checkbox-area
  .checkbox
    user-select: none
    display: flex
    align-items: center
    cursor: pointer

  .error
    width: 100%
    color: #d22518
    font-size: 13px
    line-height: 18px
    margin-top: 6px
    margin-left: 0px

  label
    text-indent: inherit
    width: auto
    height: auto
    border-radius: 0
    border: none
    &:after
      content: ''
      position: absolute
      top: 0px
      left: 0px
      width: 0px
      height: 0px
      background: transparent
      border-radius: 0px
      box-shadow: none

  .checkbox .normal-checkbox
    display: none
    opacity: 0

  .checkbox .label
    overflow: hidden
    border-radius: 2px
    border: 1px solid #2A2B31
    cursor: pointer
    height: 20px
    flex: 0 0 20px
    position: relative
    //background: #fff
    display: flex
    justify-content: center
    align-items: center

  .checkbox .normal-checkbox:checked + .label:before
    position: absolute
    width: 100%
    height: 100%
    content: ""

  .checkbox .normal-checkbox:checked + .label:after
    position: absolute
    content: ""
    background-image: url("../images/landing/checkbox.png")
    width: 15px
    height: 11px
    background-size: contain
    background-repeat: no-repeat
    background-color: transparent
    box-shadow: none
